<template>
  <b-form
    @submit.prevent="$emit('submit', model)"
    @keydown.enter="$emit('submit', model)"
  >
    <b-row>
      <b-col sm="12">
        <b-form-group
          label="Nombre"
          label-for="client-name"
        >
          <b-form-input
            id="client-name"
            :value="model.name"
            name="name"
            placeholder="Nombre"
            @input="update('name', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="País"
          label-for="client-country"
        >
          <BaseSelect
            id="client-country"
            :value="model.country"
            :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
            http-method="get"
            @input="handleCountryInput"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Provincia"
          label-for="client-province"
        >
          <BaseSelect
            id="client-province"
            ref="client-province-select"
            :value="model.province"
            :resource="$data.$constants.RESOURCES.RESOURCE_PROVINCES"
            @input="update('province', $event)"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12">
        <b-form-group
          label="Estado"
          label-for="client-status"
        >
          <StatusSelect
            id="client-status"
            :value="model.status"
            :type="statusType"
            @input="update('status', $event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import { CLIENT_STATUS_TYPE } from '@/api/status-api'
import StatusSelect from '@/components/status/select/StatusSelect.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ClientListFiltersForm',
  components: {
    BaseSelect, StatusSelect,
  },
  mixins: [FormMixin],
  data() {
    return {
      statusType: CLIENT_STATUS_TYPE,
    }
  },
  methods: {
    handleCountryInput(country) {
      this.update('country', country)
      this.$refs['client-province-select'].loadData({ country })
    },
  },
}
</script>

<style scoped>
</style>
