<template>
  <div>
    <BaseTable
      ref="clients-table"
      :resource="resource"
      :resourceStore="'clients'"
      :columns="columns"
      :filters="filters"
      hide-totals
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearClientListFilters"
      @row-clicked="$router.push({ name: 'viewClient', params: { id: $event.id } })"
    >
      <template #cell(name)="data">
        <span class="text-gray-50 font-weight-light">
          {{ data.item.name }}
        </span>
      </template>
      <template #cell(province)="data">
        <span> {{ data.item.province.name }} </span>
      </template>
      <template #cell(status)="data">
        <StatusBadge :status="data.item.status.name" />
      </template>
      <template #cell(actions)="data">
        <span>
          <b-link
            :to="{ name: 'viewClient', params: { id: data.item.id} }"
            class="pr-1 d-inline-block text-indigo"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Ver"
              icon="EyeIcon"
              size="18"
            />
          </b-link>
          <b-link
            v-access="{
              resource: $data.$constants.RESOURCES.RESOURCE_CLIENTS,
              resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
            }"
            class="d-inline-block text-danger"
            @click="deleteClient(data.item.id, data.item.name)"
          >
            <feather-icon
              v-b-tooltip.hover 
              title="Eliminar"
              icon="TrashIcon"
              size="18"
            />
          </b-link>
        </span>
      </template>
    </BaseTable>
    <ClientListFilters
      ref="client-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('clients/setFilters', $event)"
    />
  </div>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ClientListFilters from '@/components/clients/filters/ClientListFilters.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientsTable',
  components: { StatusBadge, BaseTable, ClientListFilters },
  data() {
    return {
      columns: [
        {
          label: 'NÚMERO',
          key: 'number',
          sortable: true,
        },
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: true,
          thStyle: { 'min-width': '250px' },
        },
        {
          label: 'PROVINCIA',
          key: 'province',
          sortable: true,
        },
        {
          label: 'JOBS ACTIVOS',
          key: 'active_jobs',
          sortable: true,
          thStyle: { 'min-width': '175px' },
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '200px' },
        },
      ],
      filtersVisible: false,
      resource: 'clients',
    }
  },
  computed: {
    ...mapGetters({
      filters: 'clients/getFilters',
    }),
  },
  methods: {
    handleClearClientListFilters() {
      this.$store.commit('clients/setFilters', {})
      this.$refs['client-list-filters'].clearFilters()
    },
    async deleteClient(clientId, name) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.loading = true
        await ApiRestService.delete(clientId, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        await this.$refs['clients-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
